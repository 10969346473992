'use client'

import type { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'
import type { Umbraco } from '@/types/umbraco'

import { useAnimate, useInView, useReducedMotion } from 'motion/react'
import { useEffect } from 'react'

import Container from '@/components/atoms/Container/Container'
import Button from '@/components/atoms/Button/Button'
import Heading from '@/components/atoms/Heading/Heading'
import RichText from '@/components/atoms/RichText/RichText'
import { cn } from '@/lib/cn'
import Spline from '@splinetool/react-spline'

export type StepItemProps = {
  headline?: string
  text?: Umbraco.RichTextElementModel
  link?: BaseLinkObject
  index: number
}

export default function StepItem({ headline, text, link, index }: StepItemProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  useEffect(() => {
    if (prefersReducedMotion) return

    if (isInView) {
      animate(
        '.__number',
        { opacity: [0, 1], transform: ['translateY(20px)', 'translateY(0)'] },
        { duration: 0.8, ease: [0.45, 0, 0.2, 1] },
      )
      if (headline) {
        animate(
          '.__heading-overlay',
          { transform: ['translateX(-50%)', 'translateX(51%)'] },
          { duration: 0.8, ease: [0.45, 0, 0.2, 1], delay: 0.2 },
        )
      }
      if (text) {
        animate(
          '.__text',
          { opacity: [0, 1], transform: ['translateY(20px)', 'translateY(0)'] },
          { duration: 0.8, delay: 0.4, ease: [0.45, 0, 0.2, 1] },
        )
      }
    }
  }, [isInView, animate, prefersReducedMotion, headline, text])

  return (
    <Container spacing='lg' className='!px-0 lg:py-32'>
      <div
        ref={scope}
        className='lg:grid lg:grid-cols-[1fr,2fr] lg:gap-10 xl:grid-cols-[1fr,3fr] xl:gap-16'
      >
        <div
          className={cn(
            '__number text-[15rem] leading-[12rem] mb-10 lg:mb-16 font-bold translate-y-[20px] relative',
            {
              'opacity-0': !prefersReducedMotion,
            },
          )}
        >
          <div className='absolute -top-20 -left-36 lg:-left-48 size-[30rem] scale-75'>
            <Spline scene='/spline/sphere.splinecode' />
          </div>
          <div className='relative z-10 pointer-events-none'>
            {index >= 9 ? index + 1 : `0${index + 1}`}
          </div>
        </div>

        <div>
          <div className='grid gap-10 lg:gap-16'>
            <div>
              <Heading
                size='xl'
                className='__heading max-w-4xl inline-block relative  !leading-[1.2] overflow-hidden'
              >
                {headline}
                {!prefersReducedMotion && (
                  <span className='__heading-overlay absolute top-0 left-0 w-[200%] h-full -translate-x-1/2 flex'>
                    <span className='bg-[currentColor] block w-full h-full' />
                    <span className='bg-surface-solid block w-full h-full' />
                  </span>
                )}
              </Heading>
            </div>
            {text && (
              <RichText
                content={text}
                className={cn('__text block xl:columns-2 xl:gap-10 max-w-2xl xl:max-w-none', {
                  'opacity-0': !prefersReducedMotion,
                })}
              />
            )}
            {link?.href && (
              <div className='flex justify-end'>
                <Button href={link.href} target={link.target} prefetch={link.prefetch}>
                  {link.title}
                </Button>
              </div>
            )}
          </div>
          <div className='h-px mt-10 bg-gradient-to-r from-text to-[#E01E37]' />
        </div>
      </div>
    </Container>
  )
}
