import _IconWrapper, { type IconBaseProps } from './_IconWrapper'

export default function LogoIcon(baseProps: IconBaseProps) {
  return (
    <_IconWrapper {...baseProps}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
        <path d='M5 3V22H20V17.994H9.61315V14.2455V10.2395V7.00602H20V3H5Z' fill='currentColor' />
        <path d='M20 14.2455V10.2395H12.9787V14.2455H20Z' fill='currentColor' />
      </svg>
    </_IconWrapper>
  )
}
