import type { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import type { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'

import { cn } from '@/lib/cn'

import BaseImage from '@/components/atoms/BaseImage/BaseImage'
import Button from '@/components/atoms/Button/Button'
import Heading from '@/components/atoms/Heading/Heading'
import Label from '@/components/atoms/Label/Label'
import Text from '@/components/atoms/Text/Text'

export type CardProps = {
  image?: BaseImageObject
  imageAltText?: string
  label?: string
  headline?: string
  text?: string
  link?: BaseLinkObject
  size?: 'small' | 'large'
}

export default function Card({
  image,
  label,
  headline,
  text,
  link,
  size = 'small',
  imageAltText,
}: CardProps) {
  return (
    <div
      className={cn('_card relative p-0.5 group h-full', {
        'hover:bg-card-border transition-colors focus-within:bg-card-border focus-within:duration-[2000ms] hover:duration-[2000ms] duration-700 ease-out':
          size === 'large' && link,
      })}
    >
      {size === 'large' && (
        <div className='absolute top-0 left-0 size-full overflow-hidden'>
          <div
            className={cn(
              'absolute bottom-0 -right-[200%] w-[400%] h-40 bg-gradient-to-t from-card-border to-transparent -rotate-[20deg] origin-bottom-center',
              { 'group-hover:h-[150%] group-focus-within:h-[150%]': link },
              'transition-all duration-1000 ease-out',
            )}
          />
        </div>
      )}
      <div className='bg-surface-solid shadow-lg relative z-10 h-full flex flex-col justify-between'>
        <div className='mb-4'>
          <div className='grid'>
            {image && (
              <div className='overflow-hidden col-start-1 row-start-1'>
                <BaseImage
                  {...image}
                  alt={imageAltText || image.alt}
                  aspectRatio={size === 'large' ? 410 / 368 : 410 / 205}
                  sizes='400px'
                  className={cn('relative -z-10 max-h-[21.875rem]', {
                    'group-hover:scale-105 group-focus-within:scale-105 transition-transform duration-300 ease-out':
                      link,
                    'aspect-[410/368]': size === 'large',
                    'aspect-[410/205]': size === 'small',
                  })}
                />
              </div>
            )}
            {label && (
              <div className='col-start-1 row-start-1 self-end -mb-4'>
                <Label
                  variant={size === 'large' ? 'secondary' : 'primary'}
                  className={cn({
                    'bg-surface-solid px-4 py-1 font-normal mb-2': size === 'small',
                  })}
                >
                  {label}
                </Label>
              </div>
            )}
          </div>
          <div className='px-4 pt-6'>
            <Heading size='md' tag='h2'>
              {headline}
            </Heading>
            <Text className='mt-2 line-clamp-4 max-w-xl'>{text}</Text>
          </div>
        </div>
        {link && (
          <div className='flex justify-end items-end mt-6'>
            <Button
              href={link.href}
              target={link.target}
              className='before:absolute before:top-0 before:left-0 before:size-full'
              prefetch={false}
            >
              {link.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
