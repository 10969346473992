'use client'

import type { Umbraco } from '@/types/umbraco'
import DetailCard, { type DetailCardProps } from '@/components/atoms/DetailCard/DetailCard'

import { cn } from '@/lib/cn'
import { useEffect } from 'react'
import { useAnimate, useInView, useReducedMotion } from 'motion/react'

import Container from '@/components/atoms/Container/Container'
import RichText from '@/components/atoms/RichText/RichText'
import BaseImage, { type BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import HubSpotForm, { type HubSpotFormProps } from '@/components/atoms/Forms/HubSpot/HubSpotForm'
import VimeoPlayer from '@/components/atoms/VimeoPlayer/VimeoPlayer'

export type RichTextBlockProps = {
  text?: Umbraco.RichTextElementModel
  image?: BaseImageObject
  imageAltText?: string
  detailCard?: DetailCardProps
  placeTextRight?: boolean
  hubspotForm?: HubSpotFormProps
  vimeoVideo?: string
  secondaryText?: boolean
}

export default function RichTextBlock({
  text,
  image,
  detailCard,
  placeTextRight,
  hubspotForm,
  vimeoVideo,
  imageAltText,
  secondaryText,
}: RichTextBlockProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })
  const onlyText = image ? false : true

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      if (image) {
        animate(
          '.__image',
          { opacity: [0, 1], width: ['80%', '100%'] },
          { duration: 0.8, ease: [0.45, 0, 0.2, 1] },
        )
      }

      animate(
        '.__text',
        { opacity: [0, 1], transform: ['translateY(15px)', 'translateY(0px)'] },
        {
          duration: 0.6,
          delay: onlyText ? 0 : 0.4,
          ease: [0.45, 0, 0.2, 1],
        },
      )
    }
  }, [prefersReducedMotion, isInView, scope, animate, image, onlyText])

  if (!text) return null

  return (
    <section className={cn('_rich-text-block', secondaryText && 'text-text-secondary')}>
      <div ref={scope}>
        <Container className={cn('grid gap-20 lg:grid-cols-2')}>
          {image && (
            <div
              className={cn('aspect-[560/416] lg:row-start-1 overflow-hidden', {
                'lg:col-start-1': placeTextRight,
                'lg:col-start-2 flex justify-end': !placeTextRight,
              })}
            >
              <div
                className={cn('__image transform-gpu h-full overflow-hidden', {
                  'opacity-0': !prefersReducedMotion,
                })}
              >
                <BaseImage
                  {...image}
                  alt={imageAltText || image.alt}
                  aspectRatio={560 / 416}
                  className='h-full'
                  sizes='(max-width: 1023px) 100vw, (max-width: 1799px) 50vw, 800px'
                />
              </div>
            </div>
          )}
          {detailCard && (
            <div className='lg:ml-20'>
              <DetailCard {...detailCard} />
            </div>
          )}
          {hubspotForm && (
            <div>
              <HubSpotForm {...hubspotForm} asCard secondaryText={secondaryText} />
            </div>
          )}
          {vimeoVideo && (
            <div>
              <VimeoPlayer videoId={vimeoVideo} />
            </div>
          )}
          <div
            className={cn('__text transform-gpu lg:row-start-1', {
              'lg:col-start-2': placeTextRight,
              'lg:col-start-1': !placeTextRight,
              'opacity-0': !prefersReducedMotion,
            })}
          >
            <RichText
              content={text}
              className={cn('max-w-3xl [&>*:not(h2):not(h3):not(h4)]:lg:text-lg')}
            />
          </div>
        </Container>
      </div>
    </section>
  )
}
