'use client'
import { useEffect, useRef, useState } from 'react'

/**
 * MaskSvg component props.
 *
 * @typedef {Object} MaskSvgProps
 * @property {string} svgUrl - The URL of the SVG to be used as a mask.
 * @property {string} svg - The SVG content as a string.
 */

/**
 * MaskSvg component.
 *
 * This component renders a span element with a background color and an SVG mask applied to it.
 * The aspect ratio of the SVG is calculated and applied to the span element to maintain the correct proportions.
 *
 * @param {MaskSvgProps} props - The props for the MaskSvg component.
 * @param {string} props.svgUrl - The URL of the SVG to be used as a mask.
 * @param {string} props.svg - The SVG content as a string.
 *
 * @returns {JSX.Element} The rendered MaskSvg component.
 */

type MaskSvgProps = {
  svgUrl?: string
  svg: string
  className?: string
}

const MaskSvg = ({ svgUrl, svg, className }: MaskSvgProps) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [aspectRatio, setAspectRatio] = useState(1)

  useEffect(() => {
    if (ref.current && typeof ref.current.querySelector === 'function') {
      const svgElement = ref.current.querySelector('svg')
      if (svgElement) {
        const box = svgElement.getAttribute('viewBox')
        if (box) {
          const arrBox = box.split(' ')
          const width = arrBox[2]
          const height = arrBox[3]
          if (width && height) {
            const aspectRatio = parseInt(width) / parseInt(height)
            if (aspectRatio) {
              setAspectRatio(aspectRatio)
            }
          }
        }
      }
    }
  }, [ref])

  return (
    !!svgUrl && (
      <span
        className={`_MaskSvg block size-full bg-[currentColor] ${className}`}
        style={{
          mask: `url(${svgUrl}) no-repeat center / contain`,
          aspectRatio: `${aspectRatio}`,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: svg }} className='w-0 h-0 hidden' ref={ref} />
      </span>
    )
  )
}

export default MaskSvg
