'use client'
import { AccordionItemProps } from './AccordionGroup'
import React, { useState, useId, useRef, useEffect } from 'react'
import Text from '@/components/atoms/Text/Text'
import RichText from '@/components/atoms/RichText/RichText'
import { cn } from '@/lib/cn'
import PlusMinusIcon from '@/components/icons/PlusMinusIcon'
import { useReducedMotion, motion } from 'motion/react'
import useFocusWithin from 'react-use-focus-within'

/**
 *  Follows the ARIA design pattern for an accordion from https://www.w3.org/WAI/ARIA/apg/patterns/accordion/examples/accordion/.
 *  No transition if prefers-reduced-motion
 */
export const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
  const prefersReducedMotion = useReducedMotion()
  const [isExpanded, setIsExpanded] = useState(prefersReducedMotion || false)
  const refWatchFocus = useRef<HTMLDivElement>(null)
  const isFocusWithin = useFocusWithin(refWatchFocus)

  // Generate unique identifiers
  const id = useId()
  const buttonId = `button${id}`
  const regionId = `region${id}`

  /*
    added react-use-focus-within dependency and enhance AccordionItem for focus management
    -> only change expanded state when focus goes in - and not when focus leave (to prevent too much flickering)
  */
  useEffect(() => {
    if (isFocusWithin) {
      setIsExpanded(true)
    }
  }, [isFocusWithin])

  return (
    <article className='mb-8 lg:mb-12 border-r-[1px] lg:max-w-1/2 flex-[0_0_calc(50%_-_1.5rem)]'>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        id={buttonId}
        aria-expanded={isExpanded}
        aria-controls={regionId}
        className='w-full text-left flex justify-between items-center mb-1 pr-6'
      >
        <Text tag='strong' className='pr-6'>
          {title}
        </Text>
        <PlusMinusIcon isMinus={isExpanded} className='w-4 lg:w-6' />
      </button>
      <motion.div
        className='max-w-[85%] pr-6 overflow-hidden'
        // motion:
        initial='collapsed'
        animate={!isExpanded ? 'collapsed' : 'open'}
        exit={'collapsed'}
        variants={{
          open: { height: 'auto' },
          collapsed: {
            height: '1.5em',
          },
        }}
        transition={!prefersReducedMotion ? { duration: 0.3, ease: 'easeInOut' } : { duration: 0 }}
        role='region'
        id={regionId}
        aria-labelledby={buttonId}
        aria-hidden={!isExpanded}
        ref={refWatchFocus}
      >
        <RichText
          content={content}
          className={cn({
            'line-clamp-1': !isExpanded,
          })}
        />
      </motion.div>
    </article>
  )
}
