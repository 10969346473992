import { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'
import { Umbraco } from '@/types/umbraco'

export default function convertToBaseLinkObject(
  link: Umbraco.LinkModel[] | Umbraco.LinkModel | null | undefined,
): BaseLinkObject | undefined {
  if (!link) return undefined
  const resolvedLink = Array.isArray(link) ? link[0] || {} : link || {}
  const { url, queryString, title, route, target, linkType, destinationType } = resolvedLink
  if (!url && !route?.path) return undefined

  const baseHref = url || (route?.path as string)
  const protocol = linkType === 'External' && !baseHref.startsWith('http') ? 'https://' : ''

  const noPrefetchDocTypes = ['jobListPage']
  const prefetch = destinationType ? !noPrefetchDocTypes.includes(destinationType) : false

  return {
    href: `${protocol}${baseHref}${queryString ? `${queryString}` : ''}`,
    title: title || baseHref,
    target: target || undefined,
    prefetch,
  }
}
