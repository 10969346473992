'use client'

import type { Themes } from '@/types/themes'
import type { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'

import { cn } from '@/lib/cn'
import { useAnimate, useInView, useReducedMotion, stagger } from 'motion/react'
import { useEffect } from 'react'

import BaseImage, { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import Button from '@/components/atoms/Button/Button'
import Container from '@/components/atoms/Container/Container'
import Heading from '@/components/atoms/Heading/Heading'
import Label from '@/components/atoms/Label/Label'
import Text from '@/components/atoms/Text/Text'
import Wrapper from '@/components/atoms/Wrapper/Wrapper'
import LogoMotif from '@/components/motifs/LogoMotif'

export type CTALargeProps = {
  theme?: Themes
  image?: BaseImageObject
  imageAltText?: string
  vimeoVideo?: string
  label?: string
  headline?: string
  subText?: string
  link?: BaseLinkObject
  clipMedia?: boolean
  secondaryText?: boolean
}

export default function CTALarge({
  theme = 'theme-brand-1',
  image,
  vimeoVideo,
  label,
  headline,
  subText,
  link,
  clipMedia,
  imageAltText,
  secondaryText,
}: CTALargeProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })
  const hasText = Boolean(headline || subText || (link?.href && link?.title))

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      animate(
        '.__motif',
        { opacity: [0, 1], transform: ['translateX(-20px)', 'translateX(0px)'] },
        { duration: 0.8, ease: [0.45, 0, 0.2, 1] },
      )
      if (hasText) {
        animate(
          '.__text',
          { opacity: [0, 1], transform: ['translateY(10px)', 'translateY(0px)'] },
          {
            duration: 0.4,
            delay: stagger(0.1, { startDelay: 0.4 }),
            ease: [0.45, 0, 0.2, 1],
          },
        )
      }
    }
  }, [prefersReducedMotion, isInView, scope, animate, hasText])

  const fullGrid = cn('col-start-1 col-end-2 row-start-1 row-end-2 md:col-end-3')
  const secondCol = cn('col-start-1 col-end-2 row-start-1 row-end-2 lg:col-start-2 md:col-end-3')

  return (
    <section className={'_cta-large'}>
      <Container className='px-0'>
        <div className='overflow-hidden' ref={scope}>
          <div
            className={cn(
              'min-h-[25.75rem] lg:min-h-[auto] lg:aspect-[1280/550]',
              'grid lg:grid-cols-[1fr_2fr] relative z-10',
              {
                '2xl:grid-cols-2': !clipMedia,
                'bg-surface-solid': clipMedia,
                'text-text-secondary': secondaryText,
              },
              theme,
            )}
          >
            {/* Media */}
            {!clipMedia && image && (
              <BaseImage
                aspectRatio={1280 / 524}
                {...image}
                alt={imageAltText || image.alt}
                className={cn('size-full absolute inset-0 -z-10', fullGrid)}
              />
            )}

            {/* Graiant overlay */}
            {!clipMedia && (
              <div className={cn('bg-gradient-to-b from-transparent to-surface-solid', fullGrid)} />
            )}
            <div
              className={cn(
                '__motif transform-gpu relative -z-10 lg:z-10',
                {
                  'opacity-0': !prefersReducedMotion,
                },
                fullGrid,
              )}
            >
              <LogoMotif
                variant='overlay'
                className={cn('absolute top-0 left-0 h-full')}
                image={image && clipMedia ? image : undefined}
                vimeoVideo={vimeoVideo && clipMedia ? vimeoVideo : undefined}
              />
            </div>

            {/* Content */}
            <div className={cn('grid grid-rows-[auto_1fr] w-full relative z-10', secondCol)}>
              <div className='flex justify-end'>
                <Label variant='secondary'>{label}</Label>
              </div>
              {hasText && (
                <Wrapper
                  className={cn('flex flex-col justify-end', {
                    '2xl:!pl-0': !clipMedia,
                  })}
                >
                  <Heading
                    size='lg'
                    className={cn('__text transform-gpu', {
                      'opacity-0': !prefersReducedMotion,
                    })}
                  >
                    {headline}
                  </Heading>
                  <Text
                    className={cn('mt-1 __text transform-gpu', {
                      'opacity-0': !prefersReducedMotion,
                    })}
                  >
                    {subText}
                  </Text>

                  {link?.href && link?.title && (
                    <div
                      className={cn('__text transform-gpu mt-6', {
                        'opacity-0': !prefersReducedMotion,
                      })}
                    >
                      <Button href={link.href} target={link.target} prefetch={link.prefetch}>
                        {link.title}
                      </Button>
                    </div>
                  )}
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
