import LogoIcon from '@/components/icons/LogoIcon'

import { cn } from '@/lib/cn'
import { textSizes } from '../Text/Text'

type LabelProps = {
  variant?: 'primary' | 'secondary' | 'tertiary'
  tag?: 'p' | 'span'
} & React.HTMLAttributes<HTMLElement>

export default function Label({
  children,
  className,
  variant = 'primary',
  tag: Tag = 'p',
  ...rest
}: LabelProps) {
  if (!children) return null

  return (
    <Tag
      className={cn(
        '_label',
        textSizes.sm,
        'font-bold inline-block',
        { 'inline-flex gap-2 items-center': variant !== 'primary' },
        { 'theme-base px-4 py-1 text-text bg-surface-solid': variant === 'secondary' },
        className,
      )}
      {...rest}
    >
      {variant !== 'primary' && <LogoIcon className={cn('size-6 text-logo-icon')} />}
      {children}
    </Tag>
  )
}
