'use client'
import Container from '@/components/atoms/Container/Container'
import { cn } from '@/lib/cn'
import { useEffect, useRef, useState } from 'react'
import { ProcessStep } from './components/ProcessStep/ProcessStep'
import { useDistance } from './process.hooks'
import { AnimationSvg } from './components/AnimationSvg/AnimationSvg'
import { useInView } from 'motion/react'
import Spline from '@splinetool/react-spline'
import { Umbraco } from '@/types/umbraco'

export type ProcessProps = {
  processItems?: { title?: string; description?: Umbraco.RichTextElementModel }[]
  animationStartNumber?: number
  animationEndNumber?: number
  secondaryText?: boolean
}

const Process = ({
  processItems,
  animationStartNumber,
  animationEndNumber,
  secondaryText,
}: ProcessProps) => {
  /* Animation : refs, vars,  for the container and targets */
  const refContainer = useRef<HTMLOListElement>(null!)
  const refStart = useRef<HTMLDivElement>(null!)
  const refEnd = useRef<HTMLDivElement>(null!)
  const refAnimationTrigger = useRef<HTMLDivElement>(null)

  /* states */
  const [isStartInView, setIsStartInView] = useState(false)
  const [isEndInView, setIsEndInView] = useState(false)
  const [beginAnimation, setBeginAnimation] = useState(false)

  /* hooks */
  const startPositionY = useDistance({ refContainer, refTarget: refStart })
  const endPositionY = useDistance({ refContainer, refTarget: refEnd })

  /* consts to control aspects of the animation */
  const heightOfAnimationContainer = Math.abs(endPositionY - startPositionY)
  const animationContainerDirection =
    !!animationStartNumber && !!animationEndNumber && animationStartNumber > animationEndNumber
      ? 'up'
      : 'down'
  const startEndRelationFail = animationStartNumber === animationEndNumber

  /* inView hooks */
  const isAnimationTriggerInView = useInView(refAnimationTrigger, { amount: 1, once: true })

  /*
   * When the animation trigger is in view, and the start and end are in view, and the animation has not begun, we will start the animation.
   */
  useEffect(() => {
    if (isAnimationTriggerInView && isStartInView && isEndInView && !beginAnimation) {
      setBeginAnimation(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationTriggerInView, isStartInView, isEndInView])

  // functions - inView handlers to set the animation targets viewport state
  const refStartInView = () => !isStartInView && setIsStartInView(true)
  const refEndInView = () => !isEndInView && setIsEndInView(true)

  // Spline
  const splineContainerRef = useRef<HTMLDivElement>(null)
  const splineRef = useRef<HTMLDivElement>(null)
  const isInView = useInView(splineContainerRef, { margin: '0px', once: false })

  return (
    <section
      className={cn('_process relative z-10 overflow-x-hidden bg-surface-solid', {
        'text-text-secondary': secondaryText,
      })}
      role='region'
    >
      <Container className='!max-w-screen-lg '>
        {processItems && processItems.length > 0 && (
          <ol ref={refContainer} className='relative'>
            {processItems.map(({ title, description }, index) => {
              const isStart = index + 1 === animationStartNumber && !startEndRelationFail
              const isEnd = index + 1 === animationEndNumber && !startEndRelationFail
              return (
                title &&
                description && (
                  <ProcessStep
                    title={title}
                    description={description}
                    number={index + 1}
                    key={`${title}-${index}`}
                    refStart={isStart ? refStart : null}
                    refEnd={isEnd ? refEnd : null}
                    direction={animationContainerDirection}
                    onRefStartInView={isStart ? refStartInView : undefined}
                    onRefEndInView={isEnd ? refEndInView : undefined}
                    secondaryText={secondaryText}
                  />
                )
              )
            })}
            <li aria-hidden>
              {/* 
                make animation container that is the height of the distance between the two points
              */}
              <div
                className={cn(
                  'absolute z-0 w-16 -translate-x-3 sm:-translate-x-3 md:-translate-x-[2vw] xl:-translate-x-10 text-accent-secondary',
                  {
                    '-translate-y-full': animationContainerDirection === 'up',
                    'text-text-secondary': secondaryText,
                  },
                )}
                style={{
                  height: `${heightOfAnimationContainer}px`,
                  top: `${startPositionY}px`,
                }}
              >
                <AnimationSvg
                  width={64}
                  height={heightOfAnimationContainer}
                  beginAnimation={beginAnimation}
                />
                <div className='absolute w-5 top-0 bottom-0 right-0 bg-surface-solid translate-x-1'></div>
                <div ref={refAnimationTrigger} className='h-[1px]' />
              </div>
            </li>
          </ol>
        )}
      </Container>
      <div
        className='absolute z-0 flex max-h-full w-2/3 max-w-[1200px] bottom-0 right-0 aspect-square overflow-hidden transform-gpu '
        ref={splineContainerRef}
        role='none'
      >
        <div className='translate-x-[18vw] flex-1 opacity-50'>
          <Spline
            scene={secondaryText ? '/spline/circleblue.splinecode' : '/spline/circlered.splinecode'}
            ref={splineRef}
            hidden={!isInView}
          />
        </div>
      </div>
    </section>
  )
}

export default Process
