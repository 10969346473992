'use client'

import * as motion from 'motion/react-client'
import { useReducedMotion } from 'motion/react'

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 0.565, // don't draw the whole path
    opacity: 1,
    transition: {
      pathLength: {
        delay: 0,
        type: 'spring',
        duration: 1.3,
        bounce: 0,
      },
      opacity: { duration: 0.01 },
    },
  },
}

export const AnimationSvg = ({
  width,
  height,
  beginAnimation,
}: {
  width: number
  height: number
  beginAnimation: boolean
  secondaryText?: boolean
}) => {
  const prefersReducedMotion = useReducedMotion()

  return (
    <div style={{ width, height }} className='rotate-180'>
      <motion.svg
        viewBox={`0 0 ${width} ${height}`}
        initial={prefersReducedMotion ? 'visible' : 'hidden'}
        animate={beginAnimation && 'visible'}
      >
        <motion.rect
          width='100%'
          x='4'
          y='4'
          rx='0'
          stroke='currentColor'
          variants={draw}
          style={shape}
        />
      </motion.svg>
    </div>
  )
}

/**
 * ==============   Styles   ================
 */

const shape: React.CSSProperties = {
  strokeWidth: 8,
  strokeLinecap: 'round',
  fill: 'transparent',
  height: 'calc(100% - 8px)',
  width: 'calc(100% - 8px)',
}
