'use client'

import Vimeo from '@u-wave/react-vimeo'

export type VimeoPlayerProps = {
  videoId: string
  autoPlay?: boolean
  aspectRatio?: number
  className?: string
}

export default function VimeoPlayer({
  videoId,
  autoPlay,
  aspectRatio = 16 / 9,
  className,
}: VimeoPlayerProps) {
  return (
    <div className={className} style={{ aspectRatio }}>
      <Vimeo
        video={videoId}
        autoplay={autoPlay}
        loop={autoPlay}
        muted={autoPlay}
        controls={autoPlay ? false : true}
        showByline={false}
        showTitle={false}
        showPortrait={false}
        dnt
        responsive
        playsInline
      />
    </div>
  )
}
