'use client'

import { cn } from '@/lib/cn'
import Image, { type ImageProps } from 'next/image'
import { useRef } from 'react'

export type BaseImageObject = {
  focalPoint?: { x: number; y: number }
  src: string
  alt: string
  width: number
  height: number
}

type BaseImageProps = ImageProps &
  Omit<BaseImageObject, 'src' | 'alt' | 'width' | 'height'> & {
    /** width / aspectRatio = new height */
    aspectRatio?: number
  }

/**
 * Extended `next/image` component that takes `aspectRatio` and `focalPoint` as aditional properties in order to crop images from `Umbraco`.
 * The file exports the type `BaseImageObject`, which which makes sure that CMS converters are returning valid data.
 */
export default function BaseImage({
  className,
  src,
  alt,
  width,
  height,
  aspectRatio,
  focalPoint,
  ...rest
}: BaseImageProps) {
  const imageRef = useRef<HTMLImageElement>(null)

  if (typeof width !== 'number' || typeof height !== 'number') return null

  let _width = Math.round(width)
  let _height = Math.round(aspectRatio ? _width / aspectRatio : height)

  // Umbraco media cropper has a limit of 5000px for width and height
  // If sizes are lager, rescale the image to 5000px max
  if (_width > 3000) {
    const scale = 3000 / _width
    _width = 3000
    _height = Math.round(_height * scale)
  }

  if (_height > 3000) {
    const scale = 3000 / _height
    _height = 3000
    _width = Math.round(_width * scale)
  }

  const _focalPoint = focalPoint ? `&rxy=${focalPoint.x},${focalPoint.y}` : ''
  const _src = `${src}?width=${Math.round(_width)}&height=${Math.round(_height)}${_focalPoint}`

  return (
    <Image
      ref={imageRef}
      className={cn(
        '_base-image block object-cover opacity-0 transition-opacity duration-200 w-full',
        className,
      )}
      src={_src}
      alt={alt || ''}
      width={Math.round(_width)}
      height={Math.round(_height)}
      onLoad={() => {
        if (imageRef.current) {
          imageRef.current.style.opacity = '1'
        }
      }}
      {...rest}
    />
  )
}
