'use client'
import React from 'react'
import { Umbraco } from '@/types/umbraco'
import { AccordionItem } from './AccordionItem'

export interface AccordionItemProps {
  title: string
  content: Umbraco.RichTextElementModel
}

export interface AccordionGroupProps {
  items?: AccordionItemProps[]
}

/**
 * AccordionGroup component props.
 */
export interface AccordionGroupProps {
  /**
   * An optional array of accordion items.
   */
  items?: AccordionItemProps[]
}

/**
 * AccordionItem component props.
 */
export interface AccordionItemProps {
  /**
   * The title of the accordion item.
   */
  title: string
  /**
   * The content of the accordion item, which is a rich text element model from Umbraco.
   */
  content: Umbraco.RichTextElementModel
}

/**
 * AccordionGroup component.
 *
 * This component renders a group of accordion items.
 *
 * @param {AccordionGroupProps} props - The props for the AccordionGroup component.
 * @returns {JSX.Element} The rendered AccordionGroup component.
 */
const AccordionGroup: React.FC<AccordionGroupProps> = ({ items }) => {
  return (
    <div className='_accordionCore lg:flex flex-wrap lg:gap-6 items-start'>
      {items?.map(({ title, content }, index) => {
        return (
          title &&
          content && <AccordionItem title={title} content={content} key={`${title}_${index}`} />
        )
      })}
    </div>
  )
}

export default AccordionGroup
