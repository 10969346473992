'use client'

import type { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'

import { cn } from '@/lib/cn'
import { useAnimate, useInView, useReducedMotion, stagger } from 'motion/react'
import { useEffect } from 'react'

import Container from '@/components/atoms/Container/Container'
import LogoMotif from '@/components/motifs/LogoMotif'
import Heading from '@/components/atoms/Heading/Heading'
import StatisticsItem from './StatisticsItem'

type StatisticsLargeProps = {
  headline?: string
  image?: BaseImageObject
  vimeoVideo?: string
  items?: {
    value?: string
    label?: string
  }[]
}

export default function StatisticsLarge({
  headline,
  image,
  vimeoVideo,
  items,
}: StatisticsLargeProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      if (headline) {
        animate(
          '.__headline',
          { opacity: [0, 1], transform: ['translateY(20px)', 'translateY(0px)'] },
          { duration: 0.6, ease: [0.45, 0, 0.2, 1] },
        )
      }
      if (items && items.length > 0) {
        animate(
          '.__odometer',
          { opacity: [0, 1], transform: ['translateY(20px)', 'translateY(0)'] },
          { duration: 0.6, delay: stagger(0.1, { startDelay: 0.2 }), ease: [0.45, 0, 0.2, 1] },
        )
      }
    }
  }, [isInView, items, animate, prefersReducedMotion, headline])

  return (
    <Container maxWidth='none' className='!px-0'>
      <div className='bg-surface-solid overflow-hidden' ref={scope}>
        <Container className='px-10 sm:px-20' spacing='lg'>
          <div className='relative z-10'>
            <div className='absolute bottom-0 left-0 w-full h-full -z-10 flex items-center justify-center'>
              <LogoMotif
                className='h-[calc(100%-3.25rem)]'
                variant='watermark'
                image={image}
                vimeoVideo={vimeoVideo}
              />
            </div>
            <Heading
              tag='h2'
              size='xl'
              className={cn('__headline mb-10 2xl:mb-16', { 'opacity-0': !prefersReducedMotion })}
            >
              {headline}
            </Heading>
            <ul className='grid md:grid-cols-2 gap-y-10 md:gap-y-16 lg:gap-y-20 2xl:gap-y-28 gap-x-10 lg:gap-x-24 xl:gap-x-28 2xl:gap-x-36'>
              {items?.map((item, index) => {
                if (!item.value || !item.label) return null
                return (
                  <li
                    key={index}
                    className={cn('__odometer', { 'opacity-0': !prefersReducedMotion })}
                  >
                    <StatisticsItem {...item} delay={index} size='large' start={isInView} />
                  </li>
                )
              })}
            </ul>
          </div>
        </Container>
      </div>
    </Container>
  )
}
