'use client'

import type { Themes } from '@/types/themes'
import type { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'

import { cn } from '@/lib/cn'
import { useAnimate, useInView, useReducedMotion, stagger } from 'motion/react'
import { useEffect } from 'react'

import BaseImage, { type BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import Label from '@/components/atoms/Label/Label'
import Heading from '@/components/atoms/Heading/Heading'
import Text from '@/components/atoms/Text/Text'
import Button from '@/components/atoms/Button/Button'
import Wrapper from '@/components/atoms/Wrapper/Wrapper'

export type CTASmallItemProps = {
  theme?: Themes
  image?: BaseImageObject
  imageAltText?: string
  label?: string
  headline?: string
  subText?: string
  link?: BaseLinkObject
  wide?: boolean
  secondaryText?: boolean
}

/** Single CTA small item, used in the CTASmall component */
export default function CTASmallItem({
  image,
  label,
  headline,
  subText,
  link,
  theme = 'theme-base',
  wide,
  imageAltText,
  secondaryText,
}: CTASmallItemProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  const hasText = headline || subText
  const hasLink = link?.href && link?.title

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      if (hasText) {
        animate(
          '.__text',
          { opacity: [0, 1], transform: ['translateY(10px)', 'translateY(0px)'] },
          {
            duration: 0.4,
            delay: stagger(0.1, { startDelay: 0.4 }),
            ease: [0.45, 0, 0.2, 1],
          },
        )
      }
      if (hasLink) {
        animate(
          '.__link',
          { opacity: [0, 1], transform: ['translateX(20px)', 'translateX(0px)'] },
          {
            duration: 0.4,
            delay: 0.7,
            ease: [0.45, 0, 0.2, 1],
          },
        )
      }
    }
  }, [prefersReducedMotion, isInView, scope, animate, hasText, hasLink])

  return (
    <div
      ref={scope}
      className={cn(
        '_cta-small-item',
        'min-h-96 sm:min-h-80 xl:min-h-0 xl:aspect-[628/312] grid grid-rows-[auto_1fr_auto] relative z-10 overflow-hidden',
        {
          'xl:aspect-[1280/312]': wide,
          'text-text-secondary': secondaryText,
        },
        theme,
      )}
    >
      {image && (
        <BaseImage
          {...image}
          alt={imageAltText || image.alt}
          aspectRatio={wide ? 1280 / 312 : 628 / 312}
          className='col-start-1 row-start-1 row-end-4 object-cover size-full absolute inset-0 -z-10'
        />
      )}

      {/* Gradient overlay */}
      <div className='col-start-1 row-start-1 row-end-4 bg-gradient-to-b from-transparent to-surface-solid' />

      {/* Content */}
      {label && (
        <div className='col-start-1 row-start-1 row-end-2'>
          <Label variant='secondary'>{label}</Label>
        </div>
      )}

      {hasText && (
        <Wrapper className='col-start-1 row-start-2 row-end-3 flex flex-col justify-end gap-1'>
          <Heading
            size='md'
            className={cn('__text transform-gpu', {
              'opacity-0': !prefersReducedMotion,
            })}
          >
            {headline}
          </Heading>
          <Text
            className={cn('__text transform-gpu mt-1', {
              'opacity-0': !prefersReducedMotion,
            })}
          >
            {subText}
          </Text>
        </Wrapper>
      )}

      {hasLink && (
        <div
          className={cn('__link transform-gpu col-start-1 row-start-3 row-end-4 flex justify-end', {
            'opacity-0': !prefersReducedMotion,
          })}
        >
          <Button href={link.href} target={link.target} prefetch={link.prefetch}>
            {link.title}
          </Button>
        </div>
      )}
    </div>
  )
}
