import { cn } from '@/lib/cn'
import _IconWrapper, { type IconBaseProps } from './_IconWrapper'

interface PlusMinusIconProps extends IconBaseProps {
  isMinus?: boolean
}
export default function PlusMinusIcon({ className, isMinus = false }: PlusMinusIconProps) {
  const stateStyle = cn(
    !isMinus ? 'scale-y-100' : 'scale-y-0',
    'origin-center transition-transform duration-300 ease-out',
  )

  return (
    <_IconWrapper className={className}>
      <span className='inline-flex size-full'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <g>
            <path d='M26 14V9H-1V14H26Z' fill='currentColor' />
            <path d='M15 -2L10 -2L10 25L15 25L15 -2Z' fill='currentColor' className={stateStyle} />
          </g>
        </svg>
      </span>
    </_IconWrapper>
  )
}
