'use client'

import { cn } from '@/lib/cn'
import { useAnimate, useInView, useReducedMotion, stagger } from 'motion/react'
import { useEffect } from 'react'

import Container from '@/components/atoms/Container/Container'
import Heading from '@/components/atoms/Heading/Heading'
import StatisticsItem from './StatisticsItem'

export type StatisticsSmallProps = {
  headline?: string
  items?: {
    value?: string
    label?: string
  }[]
}

export default function StatisticsSmall({ headline, items }: StatisticsSmallProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      if (items && items?.length > 0) {
        animate(
          '.__odometer',
          { opacity: [0, 1], transform: ['translateY(20px)', 'translateY(0)'] },
          { duration: 0.6, delay: stagger(0.1), ease: [0.45, 0, 0.2, 1] },
        )
      }
    }
  }, [isInView, items, animate, prefersReducedMotion])

  return (
    <Container>
      <Heading tag='h2' size='xl' className='mb-10 2xl:mb-16 max-w-4xl'>
        {headline}
      </Heading>
      <ul
        ref={scope}
        className='grid gap-6 gap-y-10 grid-cols-2 place-items-center lg:grid-cols-4 items-start'
      >
        {items?.map((item, index) => {
          if (!item.value || !item.label) return null
          return (
            <li
              key={index}
              className={cn('__odometer', {
                'opacity-0': !prefersReducedMotion,
              })}
            >
              <StatisticsItem {...item} start={isInView} delay={index} size='small' />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}
