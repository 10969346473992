import { cn } from '@/lib/cn'
import { Umbraco } from '@/types/umbraco'
import { Fragment } from 'react'
import Heading from '../Heading/Heading'
import Text from '../Text/Text'
import BaseLink from '../BaseLink/BaseLink'
import convertToBaseLinkObject from '@/lib/umbraco/converters/atoms/convertToBaseLinkObject'
import Image from 'next/image'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'

type RichTextProps = {
  content: Umbraco.RichTextElementModel
  className?: string
}

export default function RichText({ content, className }: RichTextProps) {
  return (
    <Fragment>{renderRichTextElement(content, cn('_rich-text grid gap-4', className))}</Fragment>
  )
}

const cleanText = (text?: string) => {
  if (!text) return text

  return text.replaceAll('&nbsp;', ' ').replaceAll('&amp;', '&')
}

function renderRichTextElement(element: Umbraco.RichTextElementModel, className?: string) {
  const { tag, attributes, text, elements, blocks } = element

  const children = elements
    ? elements.map((el, index) => (
        <Fragment key={index}>{renderRichTextElement({ ...el, blocks })}</Fragment>
      ))
    : null

  switch (tag) {
    case '#root':
      return <div className={className}>{children}</div>

    case '#text':
      return <>{cleanText(text)}</>

    // Custom blocks
    case 'umb-rte-block':
      if (!attributes) return null
      if (!blocks) return null
      const { 'content-id': contentId } = attributes
      const block = blocks?.find((block) => block?.content?.id === contentId)
      const content = block?.content
      if (!content) return null

      switch (content.contentType) {
        case 'vimeoVideoRow':
          if (!content.properties?.vimeoVideo) return null
          return <VimeoPlayer videoId={content.properties?.vimeoVideo} />

        default:
          console.log('Unknown block type:', content.contentType)
          return null
      }

    case 'span':
      return children

    case 'br':
      return (
        <>
          <br />
          {children}
        </>
      )

    case 'h2':
      return (
        <Heading tag='h2' size='lg' className='first:mt-0 mt-4'>
          {children}
        </Heading>
      )

    case 'h3':
      return (
        <Heading tag='h3' size='sm' className='first:mt-0 mt-2 -mb-2'>
          {children}
        </Heading>
      )

    case 'p':
      if (elements && elements[0]?.tag == 'img') return <>{children}</>
      return <Text>{children}</Text>

    case 'strong':
      return <strong className='font-bold'>{children}</strong>

    case 'em':
      return <em className='font-italic'>{children}</em>

    case 'u':
      return <u className='underline'>{children}</u>

    case 'a':
      if (!attributes) return null
      const link = {
        url: attributes?.href,
        queryString: attributes?.anchor,
        ...attributes,
      }
      const { href, target, prefetch } = convertToBaseLinkObject(link) || {}
      if (!href) return null
      return (
        <BaseLink
          href={href}
          target={target}
          prefetch={prefetch}
          className='underline text-text-anchor'
        >
          {children}
        </BaseLink>
      )

    case 'figure':
      return <figure>{children}</figure>

    case 'img':
      if (!attributes) return null
      const { src, alt = '', width, height } = attributes
      if (!src || !width || !height) return null
      return <Image src={src} alt={alt} width={width} height={height} className='w-full h-auto' /> // TODO: set the correct image width in umbraco and set correct sizes attribute here

    case 'figcaption':
      return (
        <Text tag='figcaption' className='mt-2 italic opacity-60'>
          {children}
        </Text>
      )

    case 'blockquote':
      return (
        <Text tag='blockquote' className='border-l-2 border-logo-icon pl-4'>
          {children}
        </Text>
      )

    case 'ul':
      return <ul className='list-disc ml-6'>{children}</ul>

    case 'ol':
      return <ol className='list-decimal ml-6 [&>li]:pl-0.5'>{children}</ol>

    case 'li':
      return <Text tag='li'>{children}</Text>

    default:
      console.warn(`Unknown rich text tag: ${tag}`)
      return children
  }
}
