import { cn } from '@/lib/cn'

type WrapperProps = {
  spacing?: 'sm' | 'base' | 'lg' | 'xl' | 'none'
  maxWidth?: 'base' | 'none'
  tag?: 'div' | 'span'
} & React.HTMLAttributes<HTMLElement>

/** Wrapper for content smaller content parts */
export default function Wrapper({
  children,
  spacing = 'base',
  maxWidth = 'base',
  tag: Tag = 'div',
  className,
  ...rest
}: WrapperProps) {
  return (
    <Tag
      className={cn(
        '_wrapper',
        'block',
        {
          // Spacings
          'p-4 md:p-6': spacing === 'sm',
          'py-6 px-4 md:px-10': spacing === 'base',
          'py-16 px-4 md:p-16 2xl:p-20': spacing === 'lg',
          'py-16 px-4 sm:p-10 md:p-16 lg:p-20': spacing === 'xl',

          // Max widths
          '[&>*]:max-w-2xl': maxWidth === 'base',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </Tag>
  )
}
