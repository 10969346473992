import _MotifWrapper, { MotifBaseProps } from './_MotifWrapper'

export default function XMotif(props: MotifBaseProps) {
  return (
    <_MotifWrapper {...props} aspectRatio={[550, 550]}>
      <path d='M81.7254 -0.0078125L549.267 467.533L467.541 549.259L0 81.7176L81.7254 -0.0078125Z' />
      <path d='M131.947 336.507L0.184597 468.269L81.91 549.995L213.673 418.232L131.947 336.507Z' />
      <path d='M468.275 0.210598L220.037 248.448L301.763 330.173L550 81.936L468.275 0.210598Z' />
    </_MotifWrapper>
  )
}
