import _MotifWrapper, { type MotifBaseProps } from './_MotifWrapper'

/** Aspect ratio: 554/554 */
export default function LinedXMotif(props: MotifBaseProps) {
  return (
    <_MotifWrapper {...props} aspectRatio={[554, 554]}>
      <path d='M553.261 470.939L359.423 277.101L553.987 82.5369L471.667 0.217126L277.103 194.781L90.7823 8.4601L83.6772 -0.00244141H0.00222658V82.3151L0 82.3173L0.00222658 82.3195V553.54H82.0576L82.5182 554L82.9787 553.54H83.6772L135.169 501.645V501.349L215.239 421.279L135.169 341.21V217.487L470.941 553.258L553.261 470.939Z' />
    </_MotifWrapper>
  )
}
