import type { CardProps } from './Card'

import { cn } from '@/lib/cn'
import { useAnimate, useInView, useReducedMotion } from 'motion/react'
import { useEffect } from 'react'
import Card from './Card'

export type CardWithAnimationProps = {
  card: CardProps
  size: 'small' | 'large'
  index: number
  isThreeColumnLayout: boolean
}

export default function CardWithAnimation({
  card,
  size,
  index,
  isThreeColumnLayout,
}: CardWithAnimationProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  useEffect(() => {
    if (prefersReducedMotion) return

    const windowWidth = window?.innerWidth || 0

    function getDelay() {
      if (windowWidth < 768) return 0 // Mobile single column layout
      if (windowWidth < 1024 || !isThreeColumnLayout) return (index % 2) * 0.2 // tablet || forced 2 column layout
      return (index % 3) * 0.2 // Desktop 3 column layout
    }

    if (isInView) {
      animate(
        scope.current,
        { opacity: [0, 1], transform: ['translateY(40px)', 'translateY(0)'] },
        { duration: 0.8, ease: [0.45, 0, 0.2, 1], delay: getDelay() },
      )
    }
  }, [isInView, animate, prefersReducedMotion, index, scope, isThreeColumnLayout])

  return (
    <li ref={scope} className={cn({ 'opacity-0': !prefersReducedMotion })}>
      <Card {...card} size={size} />
    </li>
  )
}
