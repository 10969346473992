import { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

/**
 * Custom hook to measure the distance between a container and a target element.
 *
 * @param param0 - Object containing references to the container and target elements.
 * @returns The distance between the container and target elements.
 */
export const useDistance = ({
  refContainer,
  refTarget,
}: {
  refContainer: React.RefObject<HTMLElement>
  refTarget: React.RefObject<HTMLDivElement>
}) => {
  const [interacted, setInteracted] = useState(false)
  const { width, height } = useWindowSize()
  const [distance, setDistance] = useState(0)

  /**
   * If the user scrolls or touches the screen, we will consider the user has interacted with the page.
   * And do the first initial measure of the distance.
   */
  useEffect(() => {
    // If interacted, we will skip adding events.
    if (interacted) return

    measureDistance()
    // Event listener.
    const listener = () => {
      setInteracted(true)
      measureDistance()
    }
    // Events attachment.
    window.addEventListener(`scroll`, listener)
    window.addEventListener(`touchstart`, listener)

    return () => {
      // clean up
      window.removeEventListener(`scroll`, listener)
      window.removeEventListener(`touchstart`, listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interacted])

  /*
   * Activate the distance-measuring between the container and the target on update on window resize event.
   */
  useEffect(() => {
    measureDistance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refContainer, refTarget, width, height])

  /*
   * Measure the distance between the container and the target.
   */
  const measureDistance = () => {
    if (refContainer?.current && refTarget?.current) {
      const containerRect = refContainer.current.getBoundingClientRect()
      const targetRect = refTarget.current.getBoundingClientRect()
      setDistance(Math.abs(containerRect.y - targetRect.y))
    }
  }

  return distance
}
