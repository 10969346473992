'use client'

import { useAnimate, useInView, useReducedMotion, stagger } from 'motion/react'
import Container from '@/components/atoms/Container/Container'
import Label from '@/components/atoms/Label/Label'
import Heading from '@/components/atoms/Heading/Heading'
import BaseLink, { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'
import BaseImage, { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'
import BaseSvg, { BaseSvgObject } from '@/components/atoms/BaseSvg/BaseSvg'
import MaskSvg from './components/MaskSvg/MaskSvg'
import { useEffect } from 'react'
import { cn } from '@/lib/cn'

export type LogoRainProps = {
  label?: string
  headline?: string
  logoLinks?: {
    logo?: BaseImageObject | BaseSvgObject
    link?: BaseLinkObject
  }[]
  secondaryText?: boolean
}

export default function LogoRain({ label, headline, logoLinks, secondaryText }: LogoRainProps) {
  /* motion setup begin */
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope)
  const prefersReducedMotion = useReducedMotion()
  const motionBaseline = {
    'opacity-0': !prefersReducedMotion,
  }
  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      animate(
        'li, p, h2',
        {
          opacity: [0, 1],
          transform: ['translateY(100%)', 'translateY(0)'],
        },
        {
          duration: 0.8,
          delay: stagger(0.1),
          ease: [0.45, 0, 0.2, 1],
        },
      )
    }
  }, [isInView])
  /* end motion setup */

  return (
    <section className={cn('_logo-rain', secondaryText && 'text-text-secondary')} ref={scope}>
      <Container className='grid gap-10 2xl:gap-16'>
        <div className='max-w-4xl'>
          <Label className={cn('mb-1', motionBaseline)}>{label}</Label>
          <Heading tag='h2' size='xl' className={cn(motionBaseline)}>
            {headline}
          </Heading>
        </div>

        {/* 
          change color of the logos by using text-[value] on the ul, eg "text-accent-secondary"
        */}
        <ul className='flex gap-8 flex-wrap justify-between'>
          {logoLinks?.map(({ logo, link }, index) => {
            if (!logo) return null

            function LogoWrapper({ children }: { children: React.ReactNode }) {
              if (link?.href)
                return (
                  <BaseLink
                    href={link.href}
                    target={link.target}
                    prefetch={link.prefetch}
                    className='h-11 block'
                  >
                    {children}
                    <span className='sr-only'>{link?.title}</span>
                  </BaseLink>
                )
              return <>{children}</>
            }

            return (
              <li key={index} className={cn(motionBaseline)}>
                <LogoWrapper>
                  {/* Keep the code below to make sure Storybook svgs are working in storybook (as these dont have the svgUrl prop as those svgs coming from Umbraco) */}
                  {(logo as BaseSvgObject).svg &&
                    (!(logo as BaseSvgObject).svgUrl ||
                      !!(logo as BaseSvgObject).enforceSvgAsCode) && (
                      <BaseSvg {...(logo as BaseSvgObject)} className='h-11 w-auto' />
                    )}
                  {(logo as BaseSvgObject).svgUrl && !(logo as BaseSvgObject).enforceSvgAsCode && (
                    <MaskSvg {...(logo as BaseSvgObject)} className='h-11 w-auto' />
                  )}
                  {(logo as BaseImageObject).src && (
                    <BaseImage {...(logo as BaseImageObject)} className='h-11 w-auto' />
                  )}
                </LogoWrapper>
              </li>
            )
          })}
        </ul>
      </Container>
    </section>
  )
}
