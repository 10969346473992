import { cn } from '@/lib/cn'

export type BaseSvgObject = {
  svg: string
  svgUrl?: string
  enforceSvgAsCode?: boolean | null
}

type BaseSvgProps = BaseSvgObject & {
  className?: string
}

export default function BaseSvg({ svg, className }: BaseSvgProps) {
  return (
    <span
      className={cn('block size-full [&>svg]:size-full', className)}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}
