'use client'

import type { Themes } from '@/types/themes'
import { useAnimate, useInView, stagger, useReducedMotion } from 'motion/react'

import Container from '@/components/atoms/Container/Container'
import Heading from '@/components/atoms/Heading/Heading'
import Wrapper from '@/components/atoms/Wrapper/Wrapper'
import { cn } from '@/lib/cn'
import LinedXMotif from '@/components/motifs/LinedXMotif'
import { useEffect } from 'react'
import Text from '@/components/atoms/Text/Text'
import BaseImage, { BaseImageObject } from '@/components/atoms/BaseImage/BaseImage'

export type StatementProps = {
  theme?: Themes
  statement?: string
  name?: string
  title?: string
  image?: BaseImageObject
  imageAltText?: string
}

export default function Statement({
  theme = 'theme-brand-1',
  statement,
  name,
  title,
  image,
  imageAltText,
}: StatementProps) {
  const prefersReducedMotion = useReducedMotion()
  const [scope, animate] = useAnimate()
  const hasImage = !!image?.src
  const isInView = useInView(scope, { margin: '0px 0px -25% 0px', once: true })

  useEffect(() => {
    if (prefersReducedMotion) return
    if (isInView) {
      if (!hasImage) {
        animate(
          '.__motif',
          {
            opacity: [0, 1],
            transform: ['translateX(20px)', 'translateX(0)'],
          },
          { duration: 0.8, ease: [0.45, 0, 0.2, 1] },
        )
      } else {
        animate(
          '.__statement-image',
          {
            opacity: [0, 1],
          },
          { duration: 0.8, ease: [0.45, 0, 0.2, 1] },
        )
      }
      if (statement) {
        animate(
          '.__quote',
          {
            opacity: [0, 1],
            transform: ['translateY(-15px)', 'translateY(0)'],
          },
          { delay: 0.4, duration: 0.4, ease: [0.45, 0, 0.2, 1] },
        )
      }

      if (name || title) {
        animate(
          '.__text',
          {
            opacity: [0, 1],
            transform: ['translateY(7px)', 'translateY(0)'],
          },
          { delay: stagger(0.1, { startDelay: 0.8 }), duration: 0.4, ease: [0.45, 0, 0.2, 1] },
        )
      }
    }
  }, [prefersReducedMotion, isInView, scope, animate, name, statement, title])

  return (
    <section className='_statement'>
      <Container className='px-0'>
        <div ref={scope}>
          <div
            className={cn('relative bg-surface-solid overflow-hidden', theme, {
              'md:grid md:grid-cols-3': hasImage,
            })}
          >
            {!hasImage && (
              <LinedXMotif
                variant='accent'
                className={cn(
                  '__motif transform-gpu absolute aspect-square min-h-[34.5625rem] h-[160%] top-6 -right-[30%] sm:-right-6',
                  {
                    'opacity-0': !prefersReducedMotion,
                  },
                )}
              />
            )}
            {hasImage && (
              <div
                className={cn('__statement-image transform-gpu col-span-1', {
                  'opacity-0': !prefersReducedMotion,
                })}
              >
                <BaseImage
                  {...image}
                  alt={imageAltText || image.alt}
                  aspectRatio={480 / 414}
                  sizes='400px'
                  className='aspect-[414 / 480] min-h-full'
                />
              </div>
            )}
            <Wrapper
              spacing='lg'
              maxWidth='none'
              className={cn('relative z-10', {
                'col-span-2': hasImage,
              })}
            >
              <Heading
                tag='blockquote'
                size='xl'
                className={cn('__quote transform-gpu max-w-6xl', {
                  'opacity-0': !prefersReducedMotion,
                })}
              >
                {statement}
              </Heading>

              {(name || title) && (
                <Wrapper spacing='none' className='mt-6'>
                  <Heading
                    tag='p'
                    size='sm'
                    className={cn('__text transform-gpu', {
                      'opacity-0': !prefersReducedMotion,
                    })}
                  >
                    {name}
                  </Heading>
                  <Text
                    size='lg'
                    className={cn('__text transform-gpu font-bold', {
                      'opacity-0': !prefersReducedMotion,
                    })}
                  >
                    {title}
                  </Text>
                </Wrapper>
              )}
            </Wrapper>
          </div>
        </div>
      </Container>
    </section>
  )
}
