'use client'

import Container from '@/components/atoms/Container/Container'
import { useEffect, useRef, useState } from 'react'

export type CodeBlockProps = {
  code?: string
}

export default function CodeBlock({ code }: CodeBlockProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (!code || !code.includes('<script')) return
    if (firstRender) {
      setFirstRender(false)
    } else {
      const parsed = document.createRange().createContextualFragment(code)
      ref.current?.appendChild(parsed)
    }
  }, [ref.current, code])

  if (!code) return null
  if (!code.includes('<script')) return <div dangerouslySetInnerHTML={{ __html: code }} />

  return (
    <Container>
      <div ref={ref} />
    </Container>
  )
}
