import _MotifWrapper, { type MotifBaseProps } from './_MotifWrapper'

/** Aspect ratio: 406/524 */
export default function LogoMotif(props: MotifBaseProps) {
  return (
    <_MotifWrapper {...props} aspectRatio={[406, 524]}>
      <path d='M0 0V524H406V413.518H124.863V110.482H406V0H0Z' />
      <path d='M406 310.139V199.657H215.957V310.139H406Z' />
    </_MotifWrapper>
  )
}
