'use client'

import Text from '@/components/atoms/Text/Text'
import '../../../styles/odometer.css'

import { useEffect, useState } from 'react'
import { useAnimate, useReducedMotion } from 'motion/react'
import { cn } from '@/lib/cn'
import dynamic from 'next/dynamic'

const ReactOdometer = dynamic(() => import('react-odometerjs'), { ssr: false })

export type StatisticsItemPropsProps = {
  value?: string
  label?: string
  start?: boolean
  delay?: number
  size: 'small' | 'large'
}

export default function StatisticsItem({
  value,
  label,
  start,
  delay,
  size,
}: StatisticsItemPropsProps) {
  const prefersReducedMotion = useReducedMotion()
  const extractedValue = value?.replace(/[^0-9,.-]/g, '') || ''
  const prefix = value?.slice(0, value?.indexOf(extractedValue))
  const suffix = value?.slice(value?.indexOf(extractedValue) + extractedValue.length)
  const numberValue = Number(extractedValue.replace(',', '.'))
  const decimals = extractedValue.includes(',')
    ? extractedValue.split(',')[1]?.length || 0
    : extractedValue.includes('.')
    ? extractedValue.split('.')[1]?.length || 0
    : 0

  const [shownValue, setShownValue] = useState(
    prefersReducedMotion ? numberValue : Math.floor(numberValue * 0.9),
  )
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (!start || prefersReducedMotion) return
    setTimeout(
      () => {
        setShownValue(numberValue)
      },
      delay ? delay * 200 : 0,
    )
    if (label) {
      animate(
        '.__label',
        { opacity: [0, 1], transform: ['translateY(10px)', 'translateY(0)'] },
        {
          duration: 0.6,
          delay: size === 'large' ? 1.2 : (delay || 0) * 0.2 + 0.4,
          ease: [0.45, 0, 0.2, 1],
        },
      )
    }
    if (size === 'large') {
      animate(
        '.__line',
        {
          opacity: [0, 1],
          width: ['0%', '100%'],
        },
        { duration: 1, delay: 0.8, ease: [0.45, 0, 0.2, 1] },
      )
    }
  }, [start, size, animate, prefersReducedMotion, delay, numberValue, label])

  if (isNaN(numberValue) || !label || !value) return null

  return (
    <>
      <div
        ref={scope}
        className={cn({ 'inline-flex gap-2 flex-col items-center': size === 'small' })}
        aria-hidden
      >
        <div
          className={cn(
            'text-4xl lg:text-5xl font-bold inline-flex items-center',
            '[mask-image:linear-gradient(transparent,transparent_10%,#000_15%,#000_80%,transparent_99%,transparent)]',
            {
              'text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-[10rem]': size === 'large',
            },
          )}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: prefix?.replaceAll('_', '&nbsp;') as string,
            }}
          ></span>
          <ReactOdometer
            value={shownValue}
            theme='minimal'
            format={decimals >= 2 ? '(ddd),dd' : decimals === 1 ? '(ddd),d' : '(ddd)'}
            duration={500}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: suffix?.replaceAll('_', '&nbsp;') as string,
            }}
          ></span>
        </div>
        <Text
          size={size === 'small' ? 'sm' : 'lg'}
          className={cn('__label', {
            'lg:text-2xl font-bold': size === 'large',
            'opacity-0': !prefersReducedMotion,
          })}
        >
          {label}
        </Text>
        {size === 'large' && (
          <div
            className={cn('__line h-px w-full bg-text mt-2', {
              'w-0': !prefersReducedMotion,
            })}
          />
        )}
      </div>
      <p className='sr-only'>
        {value} {label}
      </p>
    </>
  )
}
