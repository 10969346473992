'use client'

import type { Locale } from '@/i18n'
import type { CardProps } from './Card'

import { cn } from '@/lib/cn'
import { useState } from 'react'

import Container from '@/components/atoms/Container/Container'
import Heading from '@/components/atoms/Heading/Heading'
import Button from '@/components/atoms/Button/Button'
import CardWithAnimation from './CardWithAnimation'
import Text from '@/components/atoms/Text/Text'
import { Umbraco } from '@/types/umbraco'
import RichText from '@/components/atoms/RichText/RichText'

type LoadMoreFunctionProps = ({
  take,
  skip,
  parentId,
  locale,
}: {
  take?: number
  skip?: number
  parentId: string
  locale: Locale
}) => Promise<{ cards: CardProps[]; total: number } | null>

export type CardGridProps = {
  headline?: string
  subText?: Umbraco.RichTextElementModel
  cards?: CardProps[]
  size: 'small' | 'large'
  noCardsText?: string
  forceThreeColumnLayout?: boolean
  secondaryText?: boolean
  // Props for load more cards
  loadMore?: {
    loadMoreFunction: LoadMoreFunctionProps
    parentId: string
    locale: Locale
    total: number
    loadMoreText: string
    loadingText: string
  }
}

export default function CardGrid({
  headline,
  subText,
  cards,
  size,
  noCardsText,
  forceThreeColumnLayout,
  secondaryText,
  // Props for load more cards
  loadMore,
}: CardGridProps) {
  const [displayedCards, setDisplayedCards] = useState<CardProps[]>(cards || [])
  const [isLoading, setIsLoading] = useState(false)

  const { loadMoreFunction, parentId, locale, total, loadMoreText, loadingText } = loadMore || {}
  const hasLoadMore = loadMoreFunction && parentId && locale && displayedCards.length < (total || 0)

  const isThreeColumnLayout =
    (displayedCards?.length !== 1 &&
      displayedCards?.length !== 2 &&
      displayedCards?.length !== 4) ||
    Boolean(forceThreeColumnLayout)

  return (
    <section className={cn('_card-grid', secondaryText && 'text-text-secondary')}>
      <Container>
        <div className='max-w-4xl mb-10 2xl:mb-16'>
          <Heading tag='h2' size='xl'>
            {headline}
          </Heading>

          {subText && <RichText content={subText} className='mt-4 lg:mt-6 max-w-2xl' />}
        </div>

        {displayedCards?.length > 0 ? (
          <ul
            className={cn('grid md:grid-cols-2 gap-4', {
              'lg:grid-cols-3': isThreeColumnLayout,
            })}
          >
            {displayedCards?.map((card, index) => {
              return (
                <CardWithAnimation key={index} {...{ card, size, index, isThreeColumnLayout }} />
              )
            })}
          </ul>
        ) : (
          <Text className='max-w-4xl'>{noCardsText}</Text>
        )}

        {hasLoadMore && (
          <div className='flex justify-center items-center mt-20'>
            <Button
              noIcon
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true)
                const nextChunk = await loadMoreFunction({
                  skip: displayedCards?.length,
                  parentId,
                  locale,
                })
                if (nextChunk) setDisplayedCards([...displayedCards, ...nextChunk.cards])
                setIsLoading(false)
              }}
            >
              {isLoading ? loadingText : loadMoreText}
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
