import { cn } from '@/lib/cn'
import { useId } from 'react'

import BaseImage, { BaseImageObject } from '../atoms/BaseImage/BaseImage'
import VimeoPlayer from '../atoms/VimeoPlayer/VimeoPlayer'

export type MotifBaseProps = {
  className?: string
  /** The variant of the motif. Will be overridden by the image or video if provided. */
  variant?: 'accent' | 'watermark' | 'overlay'
  /** Set clipped image as background */
  image?: BaseImageObject
  /** Set clipped video as background. Image will be used as poster */
  vimeoVideo?: string
}

type MotifWrapperProps = MotifBaseProps & {
  children: React.ReactNode
  /** The aspect ratio of the motif. [width, height] */
  aspectRatio: [number, number]
}

/**
 * Passed `<path>` (children) will be used as clipPath, clipping the background.
 * Background can either be a colored `<span>`, `<image>` or `<video>`.
 */
export default function MotifWrapper({
  children,
  className,
  variant = 'accent',
  image,
  vimeoVideo,
  aspectRatio,
}: MotifWrapperProps) {
  const aspectWidth = aspectRatio[0]
  const aspectHeight = aspectRatio[1]

  // Unique ID for clipPath
  const clipPathId = `clip-${useId().replaceAll(':', '')}`

  const bgStyles = cn('block absolute top-0 left-0 size-full')
  const bgInlineStyles = { clipPath: `url(#${clipPathId})` }

  return (
    <div
      className={cn(
        '_motif-wrapper inline-block relative',
        {
          'opacity-30': image || vimeoVideo,
        },
        className,
      )}
      style={{ aspectRatio: `${aspectWidth}/${aspectHeight}` }}
    >
      {/* Background */}
      {image && !vimeoVideo && (
        <BaseImage
          {...image}
          aspectRatio={aspectWidth / aspectHeight}
          className={bgStyles}
          style={bgInlineStyles}
        />
      )}
      {vimeoVideo && (
        <div
          className={cn(
            bgStyles,
            'before:absolute before:top-0 before:left-0 before:size-full before:bg-text before:opacity-50',
          )}
          style={bgInlineStyles}
        >
          <VimeoPlayer
            videoId={vimeoVideo}
            autoPlay
            className='min-h-full absolute top-0 left-1/2 -translate-x-1/2'
          />
        </div>
      )}
      {!vimeoVideo && !image && (
        <span
          className={cn(bgStyles, {
            'bg-accent': variant === 'accent',
            'bg-text opacity-5': variant === 'watermark',
            'bg-text opacity-50': variant === 'overlay',
          })}
          style={bgInlineStyles}
        />
      )}

      {/* ClipPath */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={0}
        height={0}
        viewBox={`0 0 ${aspectWidth} ${aspectHeight}`}
      >
        <defs>
          <clipPath
            id={clipPathId}
            clipPathUnits='objectBoundingBox'
            transform={`scale(${1 / aspectWidth}, ${1 / aspectHeight})`} // Makes clip path scale / responsive
          >
            {/* ClipPath path / children */}
            {children}
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
