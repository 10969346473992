import Heading from '@/components/atoms/Heading/Heading'
import RichText from '@/components/atoms/RichText/RichText'
import { cn } from '@/lib/cn'
import { Umbraco } from '@/types/umbraco'
import { useInView, useReducedMotion } from 'motion/react'
import { useEffect, useRef } from 'react'
import type { Ref } from 'react'

export const ProcessStep = ({
  title,
  description,
  number,
  refStart,
  refEnd,
  direction,
  onRefStartInView,
  onRefEndInView,
  secondaryText,
}: {
  title?: string
  description?: Umbraco.RichTextElementModel
  number: number
  refStart?: Ref<HTMLDivElement>
  refEnd?: Ref<HTMLDivElement>
  direction?: 'up' | 'down'
  onRefStartInView?: () => void
  onRefEndInView?: () => void
  secondaryText?: boolean
}) => {
  const refThis = useRef<HTMLLIElement>(null)
  const isInView = useInView(refThis, { amount: 0.5, once: true })
  const prefersReducedMotion = useReducedMotion()
  const refIsInView = useRef(false)

  useEffect(() => {
    if (isInView && !refIsInView?.current) {
      refIsInView.current = true
      if (onRefStartInView) onRefStartInView()
      if (onRefEndInView) onRefEndInView()
    }
  }, [isInView, onRefStartInView, onRefEndInView])

  return (
    <li
      className={cn(
        'relative z-10 pl-[2.333em] text-3xl sm:text-4xl lg:text-5xl mb-[1.333em] transition-opacity duration-300',
        {
          'opacity-0': !isInView && !prefersReducedMotion,
          'opacity-100': isInView,
        },
      )}
      ref={refThis}
    >
      <div
        className={cn(
          'absolute left-0 top-0 bg-accent-secondary text-accent-secondary-contrast aspect-square flex items-center justify-center rounded-full font-bold w-[1.8333em] transition-opacity duration-300 delay-[50ms]',
          {
            'opacity-0': !isInView && !prefersReducedMotion,
            'opacity-100': isInView,
            'bg-text-secondary': secondaryText,
          },
        )}
      >
        {refStart && (
          <div
            className={cn('absolute w-2 h-2  left-0 top-1/2)', {
              'translate-y-2': direction === 'up',
            })}
            ref={refStart}
          />
        )}
        {refEnd && (
          <div
            className={cn('absolute w-2 h-2 left-0 top-1/2', {
              'translate-y-1': direction === 'down',
              '-translate-y-1': direction === 'up',
            })}
            ref={refEnd}
          />
        )}

        {number}
      </div>
      <Heading
        tag='h3'
        size='xl'
        className={cn('mb-2 transition-[opacity,_transform] duration-300 delay-75', {
          'opacity-0 translate-y-8': !isInView && !prefersReducedMotion,
          'opacity-100': isInView,
        })}
        aria-label={`${number}. ${title}`}
      >
        {title}
      </Heading>
      {description && (
        <RichText
          content={description}
          className={cn('transition-[opacity,_transform] duration-300 delay-100 [&>*]:!text-lg', {
            'opacity-0 translate-y-10': !isInView && !prefersReducedMotion,
            'opacity-100': isInView,
          })}
        />
      )}
    </li>
  )
}
