import { type BaseLinkObject } from '../BaseLink/BaseLink'
import Heading from '../Heading/Heading'
import Wrapper from '../Wrapper/Wrapper'
import Button from '../Button/Button'
import Text from '../Text/Text'
import XMotif from '@/components/motifs/XMotif'

export type DetailCardProps = {
  headline?: string
  details?: {
    label?: string
    value?: string | BaseLinkObject
  }[]
}

export default function DetailCard({ headline, details }: DetailCardProps) {
  return (
    <div className='_detail-card relative overflow-hidden theme-brand-3 bg-surface-solid'>
      <XMotif className='size-[32rem] absolute -bottom-20 -right-40' />
      <Wrapper className='p-10 relative z-10' maxWidth='none' spacing='lg'>
        <Heading tag='h2' size='lg'>
          {headline}
        </Heading>
        <ul className='grid md:grid-cols-2 lg:grid-cols-1 gap-6 mt-6'>
          {details?.map((item, index) => {
            const { label, value } = item
            if (!value) return null
            return (
              <li key={index}>
                {typeof value !== 'object' && (
                  <Heading size='xs' tag='h3'>
                    {label}
                  </Heading>
                )}
                {typeof value === 'string' && <Text size='lg'>{value}</Text>}
                {typeof value === 'object' && (
                  <Button
                    target={value.target}
                    href={value.href}
                    prefetch={value.prefetch}
                    className='mt-4'
                  >
                    {value.title}
                  </Button>
                )}
              </li>
            )
          })}
        </ul>
      </Wrapper>
    </div>
  )
}
