import { cn } from '@/lib/cn'

type HeadingProps = {
  size: keyof typeof headingSizes
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'blockquote'
} & React.HTMLAttributes<HTMLElement>

export const headingSizes = {
  xlExpanded: cn('text-4xl lg:text-5xl 3xl:text-6xl'),
  xl: cn('text-3xl sm:text-4xl lg:text-5xl'),
  lg: cn('text-2xl sm:text-3xl lg:text-4xl'),
  md: cn('text-xl sm:text-2xl lg:text-3xl'),
  sm: cn('text-lg sm:text-xl lg:text-2xl'),
  xs: cn('text-base sm:text-lg lg:text-xl'),
}

const baseStyles = cn('_heading font-bold')

export default function Heading({
  children,
  size,
  tag: Tag = 'h2',
  className,
  ...rest
}: HeadingProps) {
  if (!children) return null

  return (
    <Tag className={cn(baseStyles, headingSizes[size], className)} {...rest}>
      {children}
    </Tag>
  )
}
