'use client'

import { useId, useEffect } from 'react'
import Container from '../../Container/Container'
import Label from '../../Label/Label'
import Heading from '../../Heading/Heading'
import { Themes } from '@/types/themes'
import Text from '../../Text/Text'
import { cn } from '@/lib/cn'
import XMotif from '@/components/motifs/XMotif'

export type HubSpotFormProps = {
  label?: string
  headline?: string
  subText?: string
  formId?: string
  theme?: Themes
  size?: 'fullscreen' | 'wrapped'
  asCard?: boolean
  secondaryText?: boolean
}

export default function HubSpotForm({
  formId,
  label,
  headline,
  subText,
  theme = 'theme-base',
  size = 'fullscreen',
  asCard = false,
  secondaryText = false,
}: HubSpotFormProps) {
  const id = useId().replaceAll(':', '')
  const targetId = `hs-form-${id}`

  useEffect(() => {
    // Only create the form when the HubSpot script is loaded
    if (window?.hbspt && formId) {
      window.hbspt.forms.create({
        portalId: '27036621',
        formId: formId,
        target: `#${targetId}`,
        css: '',
      })
    }
  }, [targetId, formId])

  if (!formId) return null

  return (
    <section
      className={cn(
        '_hubspot-form overflow-hidden',
        {
          'bg-surface': size === 'fullscreen',
          'text-text-secondary': secondaryText,
        },
        theme,
      )}
    >
      <Container className={cn('relative', { '!p-0': asCard })}>
        <div
          className={cn({
            'bg-surface relative p-4 sm:p-6 lg:p-8 overflow-hidden': size === 'wrapped',
          })}
        >
          <div className='mb-8 grid gap-1 max-w-4xl relative z-10'>
            <Label>{label}</Label>
            <Heading tag='h2' size={asCard ? 'md' : 'xl'}>
              {headline}
            </Heading>
            <Text>{subText}</Text>
          </div>
          <div id={targetId} className={cn('hs-form relative z-10', { 'as-card': asCard })} />
          <XMotif
            className={cn('absolute bottom-0 right-0', {
              'w-80 translate-x-[35%] translate-y-[10%]': size === 'wrapped',
              'w-80 md:w-[30rem] lg:w-[35rem] translate-x-[20%] translate-y-[20%]':
                size === 'fullscreen',
            })}
          />
        </div>
      </Container>
    </section>
  )
}
